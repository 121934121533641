import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

// converts epoch milliseconds to yyyy/mm/dd
export function convertEpochDate(epochTime) {
  return epochTime.value ? new Date(epochTime.value).toISOString().slice(0, 10) : '';
}

// converts epoch milliseconds to hh:mm:ss
export function convertEpochTime(epochTime) {
  return epochTime.value
    ? new Date(epochTime.value).toLocaleTimeString(undefined, {
        hour12: false,
      })
    : '';
}

// converts epoch milliseconds start and end time to a mm/dd/yyyy - mm/dd/yyyy time range
export function convertEpochDateRange(epochStartTime, epochEndTime) {
  if (epochStartTime && epochEndTime) {
    const fromDate = new Date(epochStartTime).toISOString().slice(0, 10);
    const toDate = new Date(epochEndTime).toISOString().slice(0, 10);
    return `${fromDate} - ${toDate}`;
  }
  return '';
}

// convert milliseconds to MM/DD/YYYY - hh:mm:ss AM/PM UTC
export function convertEpochTimeUTC(tradeTime, format = 'MM/DD/YYYY - hh:mm:ss A') {
  const dayjsDate = dayjs.utc(tradeTime);
  return dayjsDate.format(format) + ' UTC';
}

// The function takes a time range (presumably an array of two dates)
// and returns an object with startTime and endTime properties,
// where startTime is the start of the first day and endTime is the end of the second day.
export function getDateRangeWithDayBounds(timeRange, isFullDay = true, isUTC = false) {
  const [start, end] = timeRange;

  const startDayjs = isUTC ? dayjs(start).utc() : dayjs(start);
  const endDayjs = isUTC ? dayjs(end).utc() : dayjs(end);

  const startTime = isFullDay ? startDayjs.startOf('day').valueOf() : startDayjs.valueOf();
  const endTime = isFullDay ? endDayjs.endOf('day').valueOf() : endDayjs.valueOf();

  return { startTime, endTime };
}

// returns day at 12:00:00 AM UTC in milliseconds based on offset from today
// (i.e., offset=0:today, offset=1:tomorrow)
export function getDayStartUTC(offset = 0) {
  const todayStartUTC = dayjs().utc().startOf('day');
  const dayWithOffset = todayStartUTC.add(offset, 'day');
  return dayWithOffset.valueOf();
}

// returns day at 11:59:59 PM UTC in milliseconds based on offset from today
// (i.e., offset=0:today, offset=1:tomorrow)
export function getDayEndUTC(offset = 0) {
  const todayEndUTC = dayjs().utc().endOf('day');
  const dayWithOffset = todayEndUTC.add(offset, 'day');
  return dayWithOffset.valueOf();
}

/**
 * Converts timestamp to a locale string with only hours and minutes.
 * @param {number} timestamp
 * @returns {string}
 */
export function getTimeLocaleString(timestamp) {
  return new Date(timestamp).toLocaleString(undefined, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}

/**
 * Converts date to a string with only year, month and day.
 * @param date
 * @returns {string}
 */
export const formatDateForBackend = (date) => {
  return dayjs(date).format('YYYYMMDD');
};

/**
 * Returns current time in milliseconds.
 * @returns {number}
 */
export const dayjsNow = () => dayjs().valueOf();

/**
 * Converts date to timestamp.
 * @param date
 * @returns {number}
 */
export const dayjsToTimestamp = (date) => dayjs(date).valueOf();

/**
 * Converts date to UTC timestamp.
 * @param date
 * @returns {number}
 */
export const dayjsToUTCTimestamp = (date) => {
  // If the date has seconds, it was generated by the Now button, then do not keep local time
  const keepLocalTime = !date.second();
  return dayjs(date).utc(keepLocalTime).valueOf();
};

/**
 * Checks if date is tomorrow.
 * @param date
 * @returns {boolean}
 */
export const isDateTomorrow = (date) => {
  return date && dayjs(date).utc().isAfter(dayjs().utc().endOf('day'));
};

export const DATE_FORMAT = {
  YYYY_MM_DD: 'YYYY-MM-DD',
  YYYY_MM_DD_hh_mm_ss: 'YYYY-MM-DD hh:mm:ss',
  YYYY_MM_DD_HH_mm_ss: 'YYYY-MM-DD HH:mm:ss',
  YYYY_MM_DD_HH_mm: 'YYYY-MM-DD HH:mm',
};
