import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api } from 'src/config';

const { Parser } = require('json2csv');
const parser = new Parser({ quote: '' });

export const marketHistoricalApi = createApi({
  reducerPath: 'marketHistoricalApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.endpoints.market}`,
  }),
  keepUnusedDataFor: 10,
  endpoints: (builder) => ({
    getOpenPrice: builder.query({
      query: (params) => ({
        url: `/priceSnapshot`,
        method: 'GET',
        params,
      }),
      transformResponse: (res) => {
        return res.map((item) => {
          const { symbol, price, itemDateTime } = item;
          const date = new Date(itemDateTime);
          const formattedTime = date.toLocaleString();
          return {
            symbol,
            price,
            lastUpdated: formattedTime,
          };
        });
      },
    }),
    getAquanowPrices: builder.query({
      query: (params) => ({
        url: `/priceSnapshot`,
        method: 'GET',
        params,
      }),
      transformResponse: (res, _, params) => {
        return {
          reportType: 'Prices',
          source: 'Aquanow',
          date: params.startTime,
          frequency: 'Daily',
          data: parser.parse(
            res.map((item) => {
              const { symbol, price, itemDateTime } = item;
              const date = new Date(itemDateTime);
              const formattedTime = date.toLocaleString();
              return {
                symbol,
                price,
                lastUpdated: formattedTime,
              };
            }),
          ),
        };
      },
    }),
    getSymbolConfig: builder.query({
      query: (params = { symbol: 'ALL' }) => ({
        url: `/symbolconfig`,
        method: 'GET',
        params,
      }),
      transformResponse(baseQueryReturnValue) {
        return Object.keys(baseQueryReturnValue);
      },
    }),
    getAssets: builder.query({
      query: (params = {}) => ({
        url: `/asset`,
        method: 'GET',
        params,
      }),
      transformResponse: (baseQueryReturnValue) => {
        return Object.entries(baseQueryReturnValue).reduce(
          (acc, [key, { type, fbAssetId }]) => {
            if (type === 'Fiat') {
              acc.fiats.push(key);
            } else if (type === 'Crypto') {
              acc.coins.push(key);
              if (fbAssetId?.length) {
                if (fbAssetId.some(({ networkType }) => networkType === 'ERC20')) {
                  acc.erc20Coins.push(key);
                }
              }
            }
            return acc;
          },
          { fiats: [], coins: [], erc20Coins: [], coinsRaw: baseQueryReturnValue },
        );
      },
    }),
  }),
});

export const {
  useGetOpenPriceQuery,
  useGetAquanowPricesQuery,
  useGetSymbolConfigQuery,
  useGetAssetsQuery,
} = marketHistoricalApi;
